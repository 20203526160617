import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from '../elements/input'
import FileField from '../elements/file'
import SelectField from '../elements/select'
import TextAreaField from '../elements/textarea'
import CheckboxField from '../elements/checkbox'
import ButtonField from '../elements/button'
import HtmlBox from '../elements/html'
import ReCaptchaBox from '../elements/recaptcha'
import '../assets/styles/_index.scss'
import { postFormData } from "../api/Api"
import bsCustomFileInput from 'bs-custom-file-input'

import axios from "axios"
import * as qs from "query-string"

function ApplyJobForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const [file, setFile] = useState(""); // storing the uploaded file

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "Apply for Job",
        error_text: "Highlighted fields are required",
        success_text: "<p>Thank you for contacting Crux Careers, we will be in touch shortly. If you have any questions please feel free to call us on <a href='tel:01344233444'>01344 233444</a>.</p>",
        email_temp_user: "apply_job_user",
        email_temp_admin: "apply_job_admin",
        email_server_func: "apply-job",
        event_tracking: "apply-job",
        page_url: ""
      },
      {
        grpmd: "6",
        label: "First Name",
        placeholder: "",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        class: ""
      },
      {
        grpmd: "6",
        label: "Last Name",
        placeholder: "",
        name: "last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        class: ""
      },
      {
        grpmd: "6",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        class: ""
      },
      {
        grpmd: "6",
        label: "Telephone",
        placeholder: "",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^([+]*[0-9-()]){9,}$",
        class: ""
      },
      {
        grpmd: "6",
        label: "Highest Level of Education",
        placeholder: "",
        name: "education",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        class: ""
      },
      {
        grpmd: "6",
        label: "Attach Your CV (doc or PDF only)",
        placeholder: "",
        name: "file",
        type: "file",
        element: "file",
        required: true,
        accept:".pdf,.doc",
        dataBrowse: "Upload",
        btnlabel: "Click to browse...",
        class: ""
      },
      {
        grpmd: "12",
        label: "Message",
        placeholder: "",
        name: "message",
        element: "textarea",
        class: "",
        rows:"5"
      },
      {
        name: "Submit Application",
        type:"submit",
        element: "button",
        value: "submit",
        groupClass: "col-xs-12",
        class: "btn btn-primary"
      },
      {
        text: '<p>By clicking submit I agree to the Crux Careers <a href="/terms-and-conditions/" className="content-link">Terms & Conditions</a></p>',
        element: "html",
        class: "form-dark__terms mt-32"
      },
      {
        text: '<p><span className="required">*</span> is a required field</p>',
        element: "html",
        class: "form-dark__required font-weight-bold mt-24 mt-md-32 mt-xl-24"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const handleAttachment = event => {
    const fileItem = event.target.files[0]; // accesing file
    setFile(fileItem); // storing file
  };


  useEffect(() => {

    // Initialize Bootstarp file upload field value retain js
    bsCustomFileInput.init()

    if (token !== '') {

      const processFromData = async () => {

        formvalues['g-recaptcha-response'] = token;

        let formData = new FormData();
        if (formvalues?.files) {
          formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
        }

        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(apiRes => {

          if (apiRes?.attachment?.url) {
            // If image from externall url, use that
            if (apiRes.attachment.url.match(/(http|https):\/\//g)) {
              formvalues['file'] = `${apiRes.attachment.url}`;
              formvalues['cvname'] = `${apiRes.attachment.url}`;
            } else { // or we consider it is loading from api url
              formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
              formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
            }
          }

          // lets send mail
          const axiosOptions_email = {
            url: '/.netlify/functions/server/'+fields[0].email_server_func,
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: qs.stringify(formvalues),
          }

          axios(axiosOptions_email)
            .then(response => {
              console.log('mail sent!')
            })
            .catch(err =>
              console.log(err)
            );
        });

        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-'+fields[0].event_tracking,
          'formId': 'form-'+fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;

      json['g-recaptcha-response'] = token;

      json['files'] = file;
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      
    }
  };

  return (
    <div className="form stbform">
    <div ref={myRef} />

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}

    {showthankyou && <div className="alert-success"dangerouslySetInnerHTML={{__html: fields[0].success_text}}></div>}
    
      <Form encType="multipart/form-data" name={fields[0].formname} action="/thank-you/" className={props.formClass} method="post" noValidate validated={validated} onSubmit={handleSubmit} data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true">
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_job" value={props.formJob} />
        <input type="hidden" name="job_url" value={props.jobUrl} />
        <input type="hidden" name="bot-field" />
        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ( "file" === field.element ) {
                return (
                  <FileField
                    name={field.name}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    type={field.type}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    accept={field.accept}
                    pattern={field.patternchk}
                    btnLabel={field.btnlabel}
                    dataBrowse={field.dataBrowse}
                    handlechange={handleAttachment}
                  />
                );
              }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  groupClass={field.groupClass}
                  fieldClass={field.class}
                  groupClass="col-md-12"
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
        }
        </Form.Row>
      </Form>
    </div>
  );
}


const ApplyJobFormPage = (props) => (
  <ApplyJobForm
    subject={props.subject}
    formClass={props.formClass}
    formJob={props.formJob}
    jobUrl={props.jobUrl}
  />
)

export default ApplyJobFormPage